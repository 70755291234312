import { PropertyData } from "./PropertyInfoCollectorTypes";
import { Localization } from "@viamap/viamap2-common";

type ParserResult = JSX.Element | string;
export function defaultParser(title: string, a: unknown): JSX.Element | string {
  if (typeof a === "string") {
    if (a.includes("data.gov.dk")) return "";
    if (a.startsWith("https://")) {
      if (a.split(".").at(-1)?.includes("/")) {
        return <a target="_blank" href={a}>Link til {a.split("/").at(2)}</a>;
      } else {
        return <a target="_blank" href={a}>Hent {a.split(".").at(-1)}-fil</a>;
      }
    }
    if (a.match(/^[a-z|0-9]{4}([a-z|0-9]{4}-){4}[a-z|0-9]{12}/)) return ""; //rem UUID
    if (a.match(/^[A-Z|0-9]{4}([A-Z|0-9]{4}-){4}[A-Z|0-9]{12}/)) return ""; //rem UUID
    if (a.match(/^POINT/)) return ""; //REM POINT
    if (a.match(/^MULTILINESTRING/)) return ""; //Rem MULTILINESTRING
    if (a.match(/[0-9]{2}T[0-9]{2}/)) return a.split("T").at(0) || "";
    return a;
  }
  if (typeof a === "number") {
    if (
      ["beløb", "værdi", "købesum","pris"].some((a) => title.toLowerCase().includes(a))
    )
      return new Intl.NumberFormat("da-dk", {
        style: "currency",
        currency: "dkk",
      })
        .format(Number(a))
        .replace(",00", "");
    if (["areal"].some((a) => title.toLowerCase().includes(a)))
      return new Intl.NumberFormat("da-dk").format(Number(a));
    if (["højde"].some((a) => title.toLowerCase().includes(a)))
      return (
        new Intl.NumberFormat("da-dk", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }).format(Number(a)) + " m"
      );
    return "" + a;
  }

  return "";
}

export function areaParser(title: string, a: unknown) {
  if (a) {
    return new Intl.NumberFormat("da-dk").format(Number(a)) + " m2";
  }
  return "";
}

export function parserTester(
  data: PropertyData
): { key: string; value: JSX.Element | string }[] {
  let keyValues = objectWalker(data, "");
  return keyValues.map((a) => ({
    key: a.key,
    value: defaultParser(a.key, a.value),
  }));
}

function constructKey(key, next, type: "obj" | "arr") {
  if (key === "") {
    switch (type) {
      case "obj":
        return `${next}`;
      case "arr":
        return `[${next}]`;
    }
  }
  switch (type) {
    case "obj":
      return key + `.${next}`;
    case "arr":
      return key + `[${next}]`;
  }
}

export function objectWalker(
  data: unknown,
  key: string
): { key: string; value: string | number }[] {
  if (Array.isArray(data)) {
    return data.flatMap((a, idx) => {
      return objectWalker(a, constructKey(key, idx, "arr"));
    });
  }
  if (typeof data === "object" && data) {
    return Object.keys(data).flatMap((a) => {
      return objectWalker(data[a], constructKey(key, a, "obj"));
    });
  }
  if (typeof data === "string" || typeof data === "number") {
    return [{ key, value: data }];
  }
  return [{ key, value: "" }];
}

export function plandataKeyParser(inkey:string | undefined) {
  if (!inkey) return ""
  let key = inkey.at(0)?.toUpperCase() + inkey.slice(1)
  if (key.includes("Anvspec")) {
    return key.replace("Anvspec", "Anvendelse - ")
  }
  if (key.startsWith("Lp")) {
    let nextKey = key.split("Lp_").at(-1)
    let step1 = titleValParser(nextKey || "")
    let step2 = plandataKeyParser(step1)
    return "Lokalplan - " + (step2 || step1 || nextKey)
  }
  const TranPos = {
    "Abestem":"Bestemmelser vdr. Skilte og Facader",
    "Fzone":"Zone Betegnelse ?",
    "Bebygpctaf":"Bebyggelses procent af",
    "Bebygpct":"Maks. Bebyggelses procent",
    "Bygpcth":"Bebyggelses procent af",
    "Bygpct":"Maks. Bebyggelse",
    "Datoforsl":"Forslag d.",
    "Datovedt":"Vedtaget d.",
    "Datoraflyst":"Aflyst d.",
    "Datoikraft": "Ikraft d.",
    "Anvendelsegenerel":"Generel Anvendelse",
    "Maxbygnhjd":"Maks. Bygningshøjde",
    "Datostart":"Hørringsperiode start d.",
    "Datoslut":"Hørringsperiode slut d.",
    "Datogeom":"Geografi ændret d.",
    "Datoattr":"Attribut ændret d.",
    "Datooprt":"Digitaliseret d. ?",
    "Datoopdt":"Ændring d.",
    "Doklink":"Dokument",
    "Minmiljo":"Mindste tilladte miljøklasse.",
    "Maxmiljo":"Højeste tilladte miljøklasse.",
    "Maxbhjd":"Maks. bygningshøjde",
    "Digigrundl":"Digitalt grundlag",
    "Maxetage":"Maks. etager",
    "Minuds":"Mindste udstykning",
    "Notanvgen":"Anvendelsesbestemmelse",
    "Notomranv":"Områdets anvendelse",
    "Notbebygom":"Bebyggelsens omfang og udformning",
    "Notmiljo":"Miljøforhold",
    "Notinfras":"Infrastruktur",
    "Notlokalp":"Lokalplan- og byplanvedtægter",
    "Notat":"Notater"
  }
  let tranPosKey = Object.keys(TranPos).find((a) => key.startsWith(a)) || "";
  return (key.match(/.*\d/) && tranPosKey && key.replace(/(.*)(\d)/, `${TranPos[tranPosKey]} - For Anvendelse $2`)) || TranPos[key]
}

export function plandataParser(key, val) {
  const exclusion = [
    "Oid","Id","Komnr","Anvgen",
  ]
  if (exclusion.includes(key)) {
    return ""
  }
  if (key.includes("Abestem") && val) {
    return "Ja"
  }
  if (key.includes("Dato") && val) {
    return new Date(
      val.toString().replace(/(\d{4}).?(\d{2}).?(\d{2})/, "$1-$2-$3")
    ).toLocaleDateString(Localization.getLocale())
  } 
  if (key.includes("Anvspec")) {
    return plandataAnvSpec(val)
  }
  if (key == "Maxbygnhjd" && val) {
    return val + "m"
  }
  if (key == "Fzone" && val) {
    return plandataZoneStatus(val)
  }
  if ((key == "Bebygpctaf" || key.startsWith("Bygpcth")) && val) {
    return plandataBygberegnaf(val)
  }
  if ((key == "Bebygpct" || key.startsWith("Bygpct")) && val) {
    return val + "%"
  }
  if (key == "Planstatus") {
    return plandataStatus(val)
  }
  if (key == "Digigrundl") {
    return digitaltGrundlag(val)
  }
  if (key == "Plantype" || key == "Objektkode") {
    return plantype(val)
  }
  return defaultParser(key, val)
}

export function plandataAnvGene(key) {
  return {
    11: "Boligområde",
    21: "Blandet bolig og erhverv",
    31: "Erhvervsområde",
    41: "Centerområde",
    51: "Rekreativt område",
    61: "Sommerhusområde",
    71: "Område til offentlige formål",
    81: "Tekniske anlæg",
    91: "Landområde",
    96: "Andet",
  }[key];
}

function digitaltGrundlag(key) {
  return {
    0:"Ikke udfyldt",
    1:"Ortofoto",
    2:"Matrikelkort",
    3:"Opmåling",
    4:"FOT / Tekniske kort",
    5:"Modelberegning",
    6:"Tegning"
  }[key];
}

export function plandataZoneStatus(key) {
  return {
    1: "Byzone",
    2: "Landzone",
    3: "Sommerhusområde",
    4: "Byzone og landzone",
    5: "Sommerhusområde og landzone",
    6: "Byzone og sommerhusområde",
    7: "Byzone, landzone og sommerhusområde",
  }[key] || "Ukendt"
}

export function plandataBygberegnaf(key) {
  return{
    1:"Området som helhed",
  2:"Den enkelte ejendom",
  3:"Den enkelte grund",
  4:"Det enkelte jordstykke",}[key] || "Ukendt"
}

export function plandataStatus(key) {
  return {
    K:"Kladde",
    KF:"Kladde til forslag",
    KV:"Kladde til vedtagelse",
    F:"Forslag",
    V:"Vedtaget",
    A:"Aflyst",
  }[key]
}

export function plandataAnvSpec(key) {
  return {
    1100: "Boligområde",
    1110: "Åben-lav boligbebyggelse",
    1120: "Tæt-lav boligbebyggelse",
    1130: "Etageboligbebyggelse",
    1150: "Almene boliger",
    1160: "Fællesanlæg",
    2120: "Landsbyområde",
    3100: "Erhvervsområde",
    3110: "Kontor- og serviceerhverv",
    3115: "Industri",
    3120: "Let industri og håndværk",
    3130: "Tung industri",
    3140: "Virksomheder med særlige beliggenhedskrav",
    3150: "Havneerhvervsområde",
    3155: "Transport- og logistikvirksomheder",
    3165: "Produktionsvirksomhed (kun relevant for kommuneplanrammer)",
    3166: "Konsekvensområde omkring produktionsvirksomheder (kun relevant for kommuneplanrammer)",
    3167: "Transformationsområde (kun relevant for kommuneplanrammer)",
    3170: "Byomdannelsesområde",
    3180: "Oplevelsesøkonomisk center",
    3190: "Fødevarevirksomhed med besøgsfaciliteter",
    4115: "Bymidte",
    4120: "Bydelscenter",
    4130: "Butikker til lokalområdets forsyning",
    4135: "Butikker",
    4170: "Lokalcenter",
    4175: "Butikker med særligt pladskrævende varer",
    4177: "Tankstation",
    4180: "Publikumsorienterede serviceerhverv",
    4190: "Aflastningsområde",
    5110: "Større rekreativt område",
    5111: "Koloni- og nyttehaver",
    5115: "Nærrekreativt område",
    5116: "Beplantning",
    5117: "Bevaringsværdig beplantning",
    5120: "Sports- og idrætsanlæg",
    5121: "Golfbaneanlæg",
    5122: "Støjende fritidsanlæg",
    5123: "Lystbådehavn",
    5130: "Ferie- og kongrescenter",
    5131: "Campingplads og vandrerhjem",
    5190: "Øvrige ferie- og fritidsformål",
    6110: "Sommerhus- og fritidsboligbebyggelse",
    7100: "Område til offentlige formål",
    7110: "Uddannelsesinstitutioner",
    7120: "Sundhedsinstitutioner",
    7133: "Daginstitutioner",
    7135: "Døgninstitutioner",
    7140: "Kulturelle institutioner",
    7150: "Offentlig administration",
    7160: "Religiøse institutioner og gravpladser",
    7180: "Fængsler",
    8100: "Tekniske anlæg",
    8110: "Forsyningsanlæg",
    8111: "Vindmølleanlæg",
    8112: "Husstandsvindmølle",
    8120: "Deponeringsanlæg",
    8130: "Rensningsanlæg",
    8133: "Solenergianlæg",
    8134: "Regnvands- og klimaanlæg",
    8160: "Biogasanlæg",
    8170: "Landskabstekniske anlæg",
    8180: "Trafikanlæg",
    8181: "Vejanlæg",
    8182: "Parkeringsanlæg",
    8183: "Stianlæg",
    8185: "Lufthavn",
    8186: "Helikopterlandingsplads",
    8190: "Trafikterminal",
    8195: "Transportkorridor",
    9100: "Landområde",
    9110: "Jordbrugsområde",
    9120: "Naturområde",
    9125: "Faunapassage",
    9130: "Militære anlæg",
    9140: "Råstofindvinding",
    9170: "Industrielt landbrug",
  }[key];
}

function plantype(key:string) {
return {
"10":"Kommuneplanramme",
"10.1": "Kommuneplanrammer",
"10.2": "Landsbyafgrænsninger",
"11": "Kommuneplan",
"12": "Kommuneplantillæg",
"20": "Lokalplan",
"20.1": "Lokalplaner",
"20.2": "Byplanvedtægter",
"30":"Lokalplan - delområder",
"30.1": "Lokalplan - delområder",
"30.2": "Byplanvedtægt - delområder",
"30.4": "Byggefelter",
"30.5": "Grupperede delområder",
"40": "Zonekort",
"41.1": "Tilført til landzone",
"50.1": "Bygningsvedtægter",
"50.2": "Byudviklingsplaner",
"50.3": "Villaservitutter",
"50.4": "Sommerhusaftaleområder",
"60": "Administrativt skel",
"70": "Kommuneplanstrategi",
"80.1": "Landzonetilladelser",
"85": "Indkaldelse af idéer og forslag",
"90": "Udbygningsaftale",
}[key]
}

export function vurdBenytParser(key: string, val: unknown):string {
  let k = -1;
  if (typeof val == "string") {
    k = parseInt(val)
  } 
  if (typeof val == "number") {
    k = val
  }
  return {
    0:"Undtaget fra vurdering",
    1:"Beboelse",
    2:"Beboelse og forretning",
    3:"Ren forretning",
    4:"Fabrik og lager",
    5:"Landbrug, bebygget, mindst 0,55 Ha",
    6:"Særskilt vurderet skov og plantage",
    7:"Frugtplantage, gartneri og planteskole",
    8:"Sommerhus",
    9:"Ubebygget areal (Ikke landbrugsareal), forskelsværdi max. 10%",
    10:"Statsejendom (Bebygget)",
    11:"Kommunal beboelses- og forretningsejendom",
    12:"Anden kommunal ejendom (Skole, rådhus m.v.)",
    13:"Andre vurderinger",
    14:"Vurdering til 0",
    16:"Udgået ejendom",
    17:"Ubebyggede landbrugslodder",
    20:"Moderejendom for ejerlejligheder",
    21:"Ejerlejlighed, beboelse",
    22:"Ejerlejlighed, beboelse og forretning",
    23:"Ejerlejlighed, ren forretning",
    24:"Ejerlejlighed, fabrik og lager",
    25:"Ejerlejlighed, i øvrigt",
    26:"Ejerlejlighed med beboelse i enfamiliehus, dobbelthus, tofamiliehus eller trefamiliehus",
    27:"Ejerlejlighed med beboelse i rækkehusbebyggelse",
    28:"Ejerlejlighed, sommerhus på fremmed grund",
    29:"Ejerlejlighed, øvrige på fremmed grund",
    31:"Støttet andelsbolig. Her medtages private andelsboliger, der efter 1. januar 1981 er finansieret med støtte efter lov om boligbyggeri. Før 2008-vurderingen",
    33:"Privat institutions- og serviceejendom",
    34:"Erhvervsejendom af speciel karakter (F.eks. varmeværk, dambrug, grusgrav, hønseri, havn)",
    41:"Beboelse på fremmed grund",
    42:"Beboelse og forretning på fremmed grund",
    43:"Ren forretning på fremmed grund",
    44:"Fabrik og lager på fremmed grund",
    45:"Anden bygning på fremmed grund",
    48:"Sommerhus på fremmed grund",
    49:"Areal med bygning på fremmed grund",
    78:"Sommerhus på fremmed grund, forbigået ved vurderingen",
    79:"Anden bygning på fremmed grund, forbigået ved vurderingen",
    98:"Opkrævningsejendom"
  }[k]||"Benyttelse endnu ikke fastlagt (Ejendom under omvurdering)"
}

export function titleValParser(key: string) {
  return (
    (key.at(0) ?? "").toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")
  )
    .replace("ae", "æ")
    .replace("oe", "ø")
    .replace("aa", "å")
    .replace("Aa","Å")
    .replace("S F E", "SFE")
    .replace("E S R", "ESR")
    .replace("V U R", "VUR")
    .replace("B B R", "BBR")
    .replace("B F E", "BFE")
    .replace("C V R", "CVR");
}